import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Press Release Writing Services",
  whatWeOffer: {
    leftPart: "Writing Press Releases is an important and time-sensitive task. As an entrepreneur, or as a company, you need a press release writing service that will help you create and publish an update, statement, or a news story on time.",
    rightPart: "Coming up with a compelling press release is no easy task. Additionally, it is time-sensitive, since there is no use in posting an outdated press release."
  },
  processText: "Luckily, our team of experienced content writers is standing by. We have already dealt with fast projects and tight deadlines, so no worries.",
  testimonials: testimonials
}

const meta = {
  title: "Press Release Writing Services",
  description: "Writing Press Releases is an important and time-sensitive task. As an entrepreneur, or as a company, you need a press release writing service that will help you create and publish an update, statement, or a news story on time."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/press-release-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)